
  
  .message-input-below-avatar {
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 150px;
    background-color: rgba(255, 255, 255, 0);
    font-weight: bold;
    color: rgba(243, 230, 230, 0.706);
  }
  

  
  .virtual-world {
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  

  
.avatar-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    width: 100px;
    height: 130px;
}
  
.avatarlok {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
  
.chat-bubble {
    position: absolute;
    top: 0; /* Start at avatar */
    background-color: rgba(240, 226, 226, 0.821); /* Fully transparent background */
    border-radius: 30px;
    padding: 8px 15px;
    max-width: 180px;
    color: rgb(239, 233, 233);
    font-weight: bold; /* Make text bold */
    font-size: 14px;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, 0.2); /* Soft border */
    animation: floatUp 5s linear infinite; /* Floating effect */
}

.message-sender {
    font-weight: bold;
    display: block;
    font-size: 12px;
    color: #333; /* Darker color for the sender's name */
    margin-bottom: 4px; /* Add some space between the name and the message */
}

.message-content {
    font-weight: bold; /* Bold message text */
    font-size: 14px;
    color: black;
}


  
/* Floating up animation */
@keyframes floatUp {
    0% {
        opacity: 1;
        transform: translateY(0); /* Start position */
    }
    100% {
        opacity: 0;
        transform: translateY(-150px); /* Float upwards */
    }
}
.input-container {
    display: flex; /* To align the input box and mic button next to each other */
    align-items: center; /* Vertically align the items */
    position: relative; /* For better control of the button position */
}


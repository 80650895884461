@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.google-btn {
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  height: 50px;
  cursor: pointer;
}
.google-btn .google-icon-wrapper {
 
  margin-left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669F2;
}


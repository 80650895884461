.rounded-img-sm{
    width: 35px;
    height: 35px;
    border-radius: 30px;
}
.rounded-img-md{
    width: 45px;
    height: 45px;
    border-radius: 40px;
}
.spinner{
    z-index: 10;
    top: 50%;
    left: 47%;
}

.main-content-boat {
  position: relative;
  height: 100vh;
  background-image: url('https://files.geetsuhane.com/boatrace/boatrace-1.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1px; */
}

.background {
  /* background-image: url('https://files.geetsuhane.com/boatrace/boatrace-1.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1px;
}
.race-timer {
  position: fixed;
  top: 10px; /* Position the timer near the top of the game area */
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px; /* Adjust font size */
  color: #fff; /* White text color for visibility */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 5px;
  border-radius: 5px;
  z-index: 100; /* Ensure it’s above other elements */
}


/* Boat bounce effect based on score */
@keyframes verticalBounce {
  0%, 100% {
    transform: translateY(0); /* Initial position */
  }
  50% {
    transform: translateY(-10px); /* Move up slightly */
  }
}

/* Horizontal shake effect */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-3px); }
  50% { transform: translateX(3px); }
  75% { transform: translateX(-3px); }
}

/* Apply shaking and moving forward based on score */
.boat-container {
  position: relative;
  width: 100%;
  max-width: 380px; /* Size of the boat container */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  animation: shake 0.5s infinite ease-in-out; /* Horizontal shaking effect */
}




.boat-image {
  width: 100%;
  max-width: 280px; /* Size of the boat image */
  height: auto;
}

.boat-info {
  position: relative;
  top: 10px;
  text-align: center;
  color: white; /* Set the boat text color to white */
  z-index: 10;
  margin-bottom: 10px;
}

.boat-info h2 {
  color: white; /* Ensure the boat name text is white */
}

.boat-info p {
  color: red; /* Set the score color to red */
}

.game {
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 82vh;
  align-items: flex-end;
  overflow-x: auto;
}

.static-boat {
  pointer-events: none; /* Prevent interaction with static boats */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.explosion-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
.avatar-animation {
  z-index: 200 !important; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4vw;
  font-weight: 900;
  color: #4CAF50;
  opacity: 1;
  animation: fly-up 2s ease-out forwards;
}

.avatar-animation.animate {
  opacity: 1;
  transform: translate(-50%, -100%); 
}

.avatar-animation.accelerate {
  color: green; 
}

.avatar-animation.destroy {
  color: red; 
} */
.combined-header {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1px 0;
  background-color: rgba(0, 0, 0, 0.688); /* Semi-transparent black background */
  z-index: 1000;
  color:#fff;
}

.header-rows {
  display: flex;
  flex-direction: column; /* Ensure two rows for team info and race timer */
  align-items: center;
  width: 100%;
}

/* Style for team info container */
.team-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping to next row */
  width: 100%;
  margin-bottom: 10px; /* Add space between team-info and race-timer */
}

.team-info {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add space between each team info */
}

.combined-header .team-info-container {
  display: flex;
  justify-content: center; /* Center the team info container */
  align-items: center;
  flex-grow: 1;
}

.combined-header .team-info {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the team info elements */
  margin-right: 10px; /* Space between teams */
}

.combined-header img {
  width: 40px; /* Smaller logo size */
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

.combined-header .team-score {
  font-size: 10px;
  font-weight: bold;
}

.combined-header .race-timer {
  top:100px;
  font-size: 15px;
  margin-top: 10px;
  font-weight: bold;
  white-space: nowrap; /* Prevent wrapping of text */
  overflow: hidden; /* Ensure it doesn’t overflow the container */
  text-align: right;
}

.combined-header.hide-timer .race-timer {
  display: none; /* Hide the race timer when the race ends */
}

.user-rank-hits-container {
  position: fixed;
  bottom: 10px; /* Push it to the bottom of the page */
  left: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional background for visibility */
  padding: 2px 0; /* Adjust the padding */
  color: white;
  z-index: 1000;
}

.rank-text,
.hits-text {
  font-size: 9px; /* Adjust font size */
  margin: 5px; /* Remove any default margin */
  padding: 1px;
}

/*mermaid*/

.position-left-center,
.position-middle-center,
.position-right-center,
.position-right-bottom {
  position: fixed;
  z-index: 2000;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  cursor: pointer; /* Ensure cursor changes to pointer */
}


.position-left-center {
  top: 40%;
  left: 25%;
  transform: translate(-50%, -50%);
  z-index: 5000; /* Ensure it is higher than other elements */
}

.position-middle-center {
  top: 30%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.position-right-center {
  top: 50%;
  right: 20%;
  transform: translate(50%, -50%);
}

.position-right-bottom {
  bottom: 20%;
  right: 20%;
}

.visible {
  opacity: 1;
}

/* Spinning animation when an image is clicked */
.spinning {
  animation: spin 0.3s linear;
  transform-origin: center; /* Set a consistent origin for rotation */
}

position-left-center img,
.position-middle-center img,
.position-right-center img,
.position-right-bottom img {
  pointer-events: auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animated value effect */
.animate-value {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  font-weight: bold;
  color: red;
  animation: fade-out 1s ease-in-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

/*avtar animation */
/* Avatar animation row */
.avatar-animation-container {
  margin-top: 15px; /* Add some space between race timer and avatar animations */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Center the avatar animation on the screen */
.avatar-animation {
  position: fixed;
  top: 23%; /* Vertically center the element */
  left: 50%; /* Horizontally center the element */
  transform: translate(-50%, -50%); /* Perfect centering */
  z-index: 500; /* Make sure it's above everything */
  font-size: 1em; /* Adjust size as needed */
  padding: 10px;
  color: rgb(247, 247, 249);
  background: linear-gradient(90deg, #014c0d, #ff414b); /* Red gradient background */
  border-radius: 10px; /* Rounded edges */
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */
}

/* Animation effect */
@keyframes avatarFadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, -40%); /* Slightly above center at the start */
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%); /* Centered in the middle */
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -60%); /* Move slightly below center */
  }
}

/* Apply the animation */
.animate {
  animation: avatarFadeInOut 1.5s ease-in-out;
}

@keyframes bobbing {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}



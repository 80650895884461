.voice-chat-room {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url('https://files.geetsuhane.com/party/party-room-bg.jpg');
    background-size: cover;
    background-position: center;
    font-family: 'Roboto', sans-serif;
    color: white;
    overflow: hidden;
}

.room-header {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 15px;
    text-align: center;
    border-bottom: 2px solid #ff5e5e;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    font-size: 24px;
}


.seats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    max-width: 600px;
    margin-bottom: 20px;
}

.seat {
    width: 80px;
    height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://files.geetsuhane.com/party/chair.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.seat img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    box-sizing: border-box;
}

.empty-chair {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    box-sizing: border-box;
}

.avatar1 img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    box-sizing: border-box;
}

.controls {
    display: flex;
    justify-content: center;
    margin-top: -10px;
    z-index: 3;
    position: absolute;
    bottom: -5px;
}

.controls button {
    background-color: #ff4040;
    color: white;
    border: none;
    padding: 3px 3px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 5px;
    box-shadow: 0 3px 3px rgba(234, 228, 228, 0.3);
    margin: 0 2px;
}

.controls button:hover {
    background-color: #ff4040;
}

.seat-name {
    margin-top: 2px;
    color: white;
    font-size: 12px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    z-index: 2;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
}

.leave-seat {
    background-color: #ff5e5e;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    margin-top: 5px;
    margin-left: 15px;
}

.leave-seat:hover {
    background-color: #ff4040;
}

.bottom-half {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.dance-floor {
    position: relative;
    width: 100%;
    height: 0px; /* Adjusted to make more space for the chat box */
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.chat-box {
    display: flex;
    flex-direction: column;
    height: 370px; /* Increased height for the chat area */
    overflow-y: auto;
}

.messages {
    flex: 1;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid #ddd;
    color: #fff;
    overflow-y: auto;
}

.message {
    padding: 5px;
    border-bottom: 1px solid #777;
}

.chat-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #444;
    border-radius: 5px;
    margin-right: 10px;
}

.chat-input button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-input button:hover {
    background-color: #555;
}

.top-half {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    position: relative;
}

@media (max-width: 768px) {
    .top-half {
        padding-bottom: 60px; /* Adjust this to control how far up or down the seats are */
    }

    .seat {
        width: 70px; /* Slightly larger seats for better visibility on mobile */
        height: 70px;
        bottom: 0; /* Ensures that the seats are aligned with the bottom */
    }
}

@media (max-width: 480px) {
    .top-half {
        padding-bottom: 60px; /* Further adjustment for very small screens */
    }

    .seat {
        width: 60px;
        height: 60px;
        bottom: 0; /* Ensures that the seats are aligned with the bottom */
    }
}

.main-content-chess {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align to the top */
  align-items: center; /* Center chessboard horizontally */
  height: 100vh;
  padding-top: 100px; /* Ensure space below the header */
}

/* Chessboard styling */
.chessboard {
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(8, 1fr);
  width: 90vw;
  height: 70vh;
  max-width: 640px;
  max-height: 640px;
  border: 5px solid #333;
  box-sizing: border-box;
  margin-top: 1px; /* Optional: Add margin to give more space above the chessboard */
  position: relative; /* Ensures it stays in place */
}

/* Ensure pieces don’t cause the board to resize */
.piece {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image fits within the square without stretching */
}

/* Ensure menu and chessboard are top-aligned */
.game-menu {
  width: 100%;
  max-width: 90vw;
  padding: 10px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 10px;
}


.square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensure borders don't increase the square size */
  border: 1px solid #444; /* Add border around each square */
  min-width: 42px;  /* Ensure a minimum size for smaller screens */
  min-height: 42px; /* Ensure a minimum size for smaller screens */
  flex-shrink: 0;   /* Prevent shrinking */
}

@media (min-width: 769px) {
  .square {
    width: 80px;
    height: 80px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .piece {
    max-width: 90%; /* Ensure the piece scales properly within its container */
    max-height: 90%;
    display: block; /* Ensure it's treated as a block element */
    margin: auto; /* Center the piece within the square */
    object-fit: contain; /* Ensure the image fits inside the square without stretching */
    padding: 5px;
  }
}



.avatar-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-icons-row {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.action-icons-row i {
  margin: 0 10px;
}

.light {
  background-color: #f0d9b5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.dark {
  background-color: #b58863;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.selected {
  border: 3px solid yellow;
}

.piece {
  cursor: pointer;
}



/* Avatar row styles */
.avatar-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

/* Each game entry style */
.game-entry {
  display: flex;
  flex-direction: column; /* Stack avatars and icons vertically */
  align-items: center;
  margin-bottom: 5px; /* Space between game entries */
  padding: 5px;
  background-color: white; /* Entry background */
  border-radius: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
}

/* Action icons row style */
.action-icons-row {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}



.avatar-vs {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.avatar-vs span {
  margin: 0 10px;
}

.game-info {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.game-info div {
  margin: 5px 0;
}

/* Flashing effect for the king when in check */
.check {
  animation: flashCheck 0.5s infinite;
  background-color: red;
  /* Additional styling for the check state */
}

@keyframes flashCheck {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}


/* Game over message styling */
.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
  font-size: 2rem;
}
.chessboard.flipped {
  transform: rotate(180deg);
}

 .square.flipped {
  transform: rotate(180deg); 
} 

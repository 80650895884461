@import url(https://fonts.googleapis.com/css?family=Roboto:500);


.rounded-img-sm{width:35px;height:35px;border-radius:30px}.rounded-img-md{width:45px;height:45px;border-radius:40px}.spinner{z-index:10;top:50%;left:47%}

.google-btn {
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  height: 50px;
  cursor: pointer;
}
.google-btn .google-icon-wrapper {
 
  margin-left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669F2;
}


.voice-chat-room {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url('https://files.geetsuhane.com/party/party-room-bg.jpg');
    background-size: cover;
    background-position: center;
    font-family: 'Roboto', sans-serif;
    color: white;
    overflow: hidden;
}

.room-header {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 15px;
    text-align: center;
    border-bottom: 2px solid #ff5e5e;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    font-size: 24px;
}


.seats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    grid-gap: 20px;
    gap: 20px;
    max-width: 600px;
    margin-bottom: 20px;
}

.seat {
    width: 80px;
    height: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://files.geetsuhane.com/party/chair.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.seat img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    box-sizing: border-box;
}

.empty-chair {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    box-sizing: border-box;
}

.avatar1 img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    box-sizing: border-box;
}

.controls {
    display: flex;
    justify-content: center;
    margin-top: -10px;
    z-index: 3;
    position: absolute;
    bottom: -5px;
}

.controls button {
    background-color: #ff4040;
    color: white;
    border: none;
    padding: 3px 3px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 5px;
    box-shadow: 0 3px 3px rgba(234, 228, 228, 0.3);
    margin: 0 2px;
}

.controls button:hover {
    background-color: #ff4040;
}

.seat-name {
    margin-top: 2px;
    color: white;
    font-size: 12px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    z-index: 2;
    position: absolute;
    bottom: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.leave-seat {
    background-color: #ff5e5e;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    margin-top: 5px;
    margin-left: 15px;
}

.leave-seat:hover {
    background-color: #ff4040;
}

.bottom-half {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.dance-floor {
    position: relative;
    width: 100%;
    height: 0px; /* Adjusted to make more space for the chat box */
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.chat-box {
    display: flex;
    flex-direction: column;
    height: 370px; /* Increased height for the chat area */
    overflow-y: auto;
}

.messages {
    flex: 1 1;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid #ddd;
    color: #fff;
    overflow-y: auto;
}

.message {
    padding: 5px;
    border-bottom: 1px solid #777;
}

.chat-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.chat-input input {
    flex: 1 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #444;
    border-radius: 5px;
    margin-right: 10px;
}

.chat-input button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-input button:hover {
    background-color: #555;
}

.top-half {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    position: relative;
}

@media (max-width: 768px) {
    .top-half {
        padding-bottom: 60px; /* Adjust this to control how far up or down the seats are */
    }

    .seat {
        width: 70px; /* Slightly larger seats for better visibility on mobile */
        height: 70px;
        bottom: 0; /* Ensures that the seats are aligned with the bottom */
    }
}

@media (max-width: 480px) {
    .top-half {
        padding-bottom: 60px; /* Further adjustment for very small screens */
    }

    .seat {
        width: 60px;
        height: 60px;
        bottom: 0; /* Ensures that the seats are aligned with the bottom */
    }
}

.main-content-boat {
  position: relative;
  height: 100vh;
  background-image: url('https://files.geetsuhane.com/boatrace/boatrace-1.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1px; */
}

.background {
  /* background-image: url('https://files.geetsuhane.com/boatrace/boatrace-1.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1px;
}
.race-timer {
  position: fixed;
  top: 10px; /* Position the timer near the top of the game area */
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 20px; /* Adjust font size */
  color: #fff; /* White text color for visibility */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 5px;
  border-radius: 5px;
  z-index: 100; /* Ensure it’s above other elements */
}


/* Boat bounce effect based on score */
@-webkit-keyframes verticalBounce {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Initial position */
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); /* Move up slightly */
  }
}
@keyframes verticalBounce {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Initial position */
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); /* Move up slightly */
  }
}

/* Horizontal shake effect */
@-webkit-keyframes shake {
  0%, 100% { -webkit-transform: translateX(0); transform: translateX(0); }
  25% { -webkit-transform: translateX(-3px); transform: translateX(-3px); }
  50% { -webkit-transform: translateX(3px); transform: translateX(3px); }
  75% { -webkit-transform: translateX(-3px); transform: translateX(-3px); }
}
@keyframes shake {
  0%, 100% { -webkit-transform: translateX(0); transform: translateX(0); }
  25% { -webkit-transform: translateX(-3px); transform: translateX(-3px); }
  50% { -webkit-transform: translateX(3px); transform: translateX(3px); }
  75% { -webkit-transform: translateX(-3px); transform: translateX(-3px); }
}

/* Apply shaking and moving forward based on score */
.boat-container {
  position: relative;
  width: 100%;
  max-width: 380px; /* Size of the boat container */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  -webkit-animation: shake 0.5s infinite ease-in-out;
          animation: shake 0.5s infinite ease-in-out; /* Horizontal shaking effect */
}




.boat-image {
  width: 100%;
  max-width: 280px; /* Size of the boat image */
  height: auto;
}

.boat-info {
  position: relative;
  top: 10px;
  text-align: center;
  color: white; /* Set the boat text color to white */
  z-index: 10;
  margin-bottom: 10px;
}

.boat-info h2 {
  color: white; /* Ensure the boat name text is white */
}

.boat-info p {
  color: red; /* Set the score color to red */
}

.game {
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 82vh;
  align-items: flex-end;
  overflow-x: auto;
}

.static-boat {
  pointer-events: none; /* Prevent interaction with static boats */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.explosion-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
.avatar-animation {
  z-index: 200 !important; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4vw;
  font-weight: 900;
  color: #4CAF50;
  opacity: 1;
  animation: fly-up 2s ease-out forwards;
}

.avatar-animation.animate {
  opacity: 1;
  transform: translate(-50%, -100%); 
}

.avatar-animation.accelerate {
  color: green; 
}

.avatar-animation.destroy {
  color: red; 
} */
.combined-header {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1px 0;
  background-color: rgba(0, 0, 0, 0.688); /* Semi-transparent black background */
  z-index: 1000;
  color:#fff;
}

.header-rows {
  display: flex;
  flex-direction: column; /* Ensure two rows for team info and race timer */
  align-items: center;
  width: 100%;
}

/* Style for team info container */
.team-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping to next row */
  width: 100%;
  margin-bottom: 10px; /* Add space between team-info and race-timer */
}

.team-info {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add space between each team info */
}

.combined-header .team-info-container {
  display: flex;
  justify-content: center; /* Center the team info container */
  align-items: center;
  flex-grow: 1;
}

.combined-header .team-info {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the team info elements */
  margin-right: 10px; /* Space between teams */
}

.combined-header img {
  width: 40px; /* Smaller logo size */
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

.combined-header .team-score {
  font-size: 10px;
  font-weight: bold;
}

.combined-header .race-timer {
  top:100px;
  font-size: 15px;
  margin-top: 10px;
  font-weight: bold;
  white-space: nowrap; /* Prevent wrapping of text */
  overflow: hidden; /* Ensure it doesn’t overflow the container */
  text-align: right;
}

.combined-header.hide-timer .race-timer {
  display: none; /* Hide the race timer when the race ends */
}

.user-rank-hits-container {
  position: fixed;
  bottom: 10px; /* Push it to the bottom of the page */
  left: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); /* Optional background for visibility */
  padding: 2px 0; /* Adjust the padding */
  color: white;
  z-index: 1000;
}

.rank-text,
.hits-text {
  font-size: 9px; /* Adjust font size */
  margin: 5px; /* Remove any default margin */
  padding: 1px;
}

/*mermaid*/

.position-left-center,
.position-middle-center,
.position-right-center,
.position-right-bottom {
  position: fixed;
  z-index: 2000;
  opacity: 0;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer; /* Ensure cursor changes to pointer */
}


.position-left-center {
  top: 40%;
  left: 25%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5000; /* Ensure it is higher than other elements */
}

.position-middle-center {
  top: 30%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.position-right-center {
  top: 50%;
  right: 20%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.position-right-bottom {
  bottom: 20%;
  right: 20%;
}

.visible {
  opacity: 1;
}

/* Spinning animation when an image is clicked */
.spinning {
  -webkit-animation: spin 0.3s linear;
          animation: spin 0.3s linear;
  -webkit-transform-origin: center;
          transform-origin: center; /* Set a consistent origin for rotation */
}

position-left-center img,
.position-middle-center img,
.position-right-center img,
.position-right-bottom img {
  pointer-events: auto;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Animated value effect */
.animate-value {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 36px;
  font-weight: bold;
  color: red;
  -webkit-animation: fade-out 1s ease-in-out;
          animation: fade-out 1s ease-in-out;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

/*avtar animation */
/* Avatar animation row */
.avatar-animation-container {
  margin-top: 15px; /* Add some space between race timer and avatar animations */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Center the avatar animation on the screen */
.avatar-animation {
  position: fixed;
  top: 23%; /* Vertically center the element */
  left: 50%; /* Horizontally center the element */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); /* Perfect centering */
  z-index: 500; /* Make sure it's above everything */
  font-size: 1em; /* Adjust size as needed */
  padding: 10px;
  color: rgb(247, 247, 249);
  background: linear-gradient(90deg, #014c0d, #ff414b); /* Red gradient background */
  border-radius: 10px; /* Rounded edges */
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */
}

/* Animation effect */
@-webkit-keyframes avatarFadeInOut {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%); /* Slightly above center at the start */
  }
  50% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); /* Centered in the middle */
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%); /* Move slightly below center */
  }
}
@keyframes avatarFadeInOut {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%); /* Slightly above center at the start */
  }
  50% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); /* Centered in the middle */
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%); /* Move slightly below center */
  }
}

/* Apply the animation */
.animate {
  -webkit-animation: avatarFadeInOut 1.5s ease-in-out;
          animation: avatarFadeInOut 1.5s ease-in-out;
}

@-webkit-keyframes bobbing {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes bobbing {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}



.team-selection-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 80vh;
  box-sizing: border-box;
}

.top-controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.race-start-info {
  font-size: 1em;
  color: white;
  margin-bottom: 10px;
  text-align: center;
}

.join-button {
  margin-top: 10px;
}

.team-selection {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
  justify-content: center;
  width: 100%;
}

.team-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.team-card:hover, .team-card.selected {
  background-color: #e9ecef;
}

.team-members {
  margin-top: 10px;
  font-size: 14px;
  color: #6c757d;
}

.text-center {
  text-align: center;
  font-weight: bold;
}
.food-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align to the top */
  max-height: 245px; /* Set maximum height */
  margin-top: 0px;
  margin-bottom: 20px; /* Add some space below the food */
  overflow: hidden; /* Prevent content overflow */
}

.food-image {
  max-height: 245px; /* Limit the food image height to 200px */
  width: auto; /* Keep the aspect ratio */
  object-fit: contain; /* Ensure the image fits within the container */
}

.grab-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); /* Center the button */
  background-color: #ff6b6b; /* Customize the button color */
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
}


.rank-button {
  color: white;
  font-size: 14px;
  padding: 10px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.grab-button:hover {
  background-color: #ff3b3b; /* Darker color on hover */
}
/*avatar animations*/

.race-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  /* background-color: lightgray; */
  padding: 0 10px;
}

.avatar1 {
  width: 50px;
  height: 50px;
  /* background-color: red; */
  border-radius: 30%;
  position: relative;
  transition: all 0.5s ease;
}

/* Animation to push avatars from left to right */
@-webkit-keyframes pushLeftRight {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); /* Start and end at original position */
  }
  25% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px); /* Push to the left */
  }
  75% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);  /* Push to the right */
  }
}
@keyframes pushLeftRight {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); /* Start and end at original position */
  }
  25% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px); /* Push to the left */
  }
  75% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);  /* Push to the right */
  }
}

/* Apply the animation to each avatar with reduced duration */
.avatar1 {
  -webkit-animation: pushLeftRight 1.5s ease-in-out infinite;
          animation: pushLeftRight 1.5s ease-in-out infinite; /* Faster animation */
}

/* Adding staggered delay to each avatar */
.avatar-1 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.avatar-2 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.avatar-3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.avatar-4 {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.avatar-5 {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.avatar-6 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.video-container {
  display: flex;
  align-items: center;
 
  /* background-color: lightgray; */
  margin: 0 auto; /* Center the video */
  overflow: hidden; /* Prevent video from exceeding its container */
}


.main-content-chess {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align to the top */
  align-items: center; /* Center chessboard horizontally */
  height: 100vh;
  padding-top: 100px; /* Ensure space below the header */
}

/* Chessboard styling */
.chessboard {
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(8, 1fr);
  width: 90vw;
  height: 70vh;
  max-width: 640px;
  max-height: 640px;
  border: 5px solid #333;
  box-sizing: border-box;
  margin-top: 1px; /* Optional: Add margin to give more space above the chessboard */
  position: relative; /* Ensures it stays in place */
}

/* Ensure pieces don’t cause the board to resize */
.piece {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image fits within the square without stretching */
}

/* Ensure menu and chessboard are top-aligned */
.game-menu {
  width: 100%;
  max-width: 90vw;
  padding: 10px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 10px;
}


.square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensure borders don't increase the square size */
  border: 1px solid #444; /* Add border around each square */
  min-width: 42px;  /* Ensure a minimum size for smaller screens */
  min-height: 42px; /* Ensure a minimum size for smaller screens */
  flex-shrink: 0;   /* Prevent shrinking */
}

@media (min-width: 769px) {
  .square {
    width: 80px;
    height: 80px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .piece {
    max-width: 90%; /* Ensure the piece scales properly within its container */
    max-height: 90%;
    display: block; /* Ensure it's treated as a block element */
    margin: auto; /* Center the piece within the square */
    object-fit: contain; /* Ensure the image fits inside the square without stretching */
    padding: 5px;
  }
}



.avatar-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-icons-row {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.action-icons-row i {
  margin: 0 10px;
}

.light {
  background-color: #f0d9b5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.dark {
  background-color: #b58863;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.selected {
  border: 3px solid yellow;
}

.piece {
  cursor: pointer;
}



/* Avatar row styles */
.avatar-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

/* Each game entry style */
.game-entry {
  display: flex;
  flex-direction: column; /* Stack avatars and icons vertically */
  align-items: center;
  margin-bottom: 5px; /* Space between game entries */
  padding: 5px;
  background-color: white; /* Entry background */
  border-radius: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
}

/* Action icons row style */
.action-icons-row {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}



.avatar-vs {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.avatar-vs span {
  margin: 0 10px;
}

.game-info {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.game-info div {
  margin: 5px 0;
}

/* Flashing effect for the king when in check */
.check {
  -webkit-animation: flashCheck 0.5s infinite;
          animation: flashCheck 0.5s infinite;
  background-color: red;
  /* Additional styling for the check state */
}

@-webkit-keyframes flashCheck {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes flashCheck {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}


/* Game over message styling */
.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
  font-size: 2rem;
}
.chessboard.flipped {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

 .square.flipped {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); 
} 


  
  .message-input-below-avatar {
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 150px;
    background-color: rgba(255, 255, 255, 0);
    font-weight: bold;
    color: rgba(243, 230, 230, 0.706);
  }
  

  
  .virtual-world {
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  

  
.avatar-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    width: 100px;
    height: 130px;
}
  
.avatarlok {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
  
.chat-bubble {
    position: absolute;
    top: 0; /* Start at avatar */
    background-color: rgba(240, 226, 226, 0.821); /* Fully transparent background */
    border-radius: 30px;
    padding: 8px 15px;
    max-width: 180px;
    color: rgb(239, 233, 233);
    font-weight: bold; /* Make text bold */
    font-size: 14px;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, 0.2); /* Soft border */
    -webkit-animation: floatUp 5s linear infinite;
            animation: floatUp 5s linear infinite; /* Floating effect */
}

.message-sender {
    font-weight: bold;
    display: block;
    font-size: 12px;
    color: #333; /* Darker color for the sender's name */
    margin-bottom: 4px; /* Add some space between the name and the message */
}

.message-content {
    font-weight: bold; /* Bold message text */
    font-size: 14px;
    color: black;
}


  
/* Floating up animation */
@-webkit-keyframes floatUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0); /* Start position */
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-150px);
                transform: translateY(-150px); /* Float upwards */
    }
}
@keyframes floatUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0); /* Start position */
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-150px);
                transform: translateY(-150px); /* Float upwards */
    }
}
.input-container {
    display: flex; /* To align the input box and mic button next to each other */
    align-items: center; /* Vertically align the items */
    position: relative; /* For better control of the button position */
}



.team-selection-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 80vh;
  box-sizing: border-box;
}

.top-controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.race-start-info {
  font-size: 1em;
  color: white;
  margin-bottom: 10px;
  text-align: center;
}

.join-button {
  margin-top: 10px;
}

.team-selection {
  display: grid;
  gap: 12px;
  justify-content: center;
  width: 100%;
}

.team-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.team-card:hover, .team-card.selected {
  background-color: #e9ecef;
}

.team-members {
  margin-top: 10px;
  font-size: 14px;
  color: #6c757d;
}

.text-center {
  text-align: center;
  font-weight: bold;
}
.food-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align to the top */
  max-height: 245px; /* Set maximum height */
  margin-top: 0px;
  margin-bottom: 20px; /* Add some space below the food */
  overflow: hidden; /* Prevent content overflow */
}

.food-image {
  max-height: 245px; /* Limit the food image height to 200px */
  width: auto; /* Keep the aspect ratio */
  object-fit: contain; /* Ensure the image fits within the container */
}

.grab-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the button */
  background-color: #ff6b6b; /* Customize the button color */
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
}


.rank-button {
  color: white;
  font-size: 14px;
  padding: 10px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.grab-button:hover {
  background-color: #ff3b3b; /* Darker color on hover */
}
/*avatar animations*/

.race-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  /* background-color: lightgray; */
  padding: 0 10px;
}

.avatar1 {
  width: 50px;
  height: 50px;
  /* background-color: red; */
  border-radius: 30%;
  position: relative;
  transition: all 0.5s ease;
}

/* Animation to push avatars from left to right */
@keyframes pushLeftRight {
  0%, 100% {
    transform: translateX(0); /* Start and end at original position */
  }
  25% {
    transform: translateX(-30px); /* Push to the left */
  }
  75% {
    transform: translateX(30px);  /* Push to the right */
  }
}

/* Apply the animation to each avatar with reduced duration */
.avatar1 {
  animation: pushLeftRight 1.5s ease-in-out infinite; /* Faster animation */
}

/* Adding staggered delay to each avatar */
.avatar-1 {
  animation-delay: 0s;
}

.avatar-2 {
  animation-delay: 0.2s;
}

.avatar-3 {
  animation-delay: 0.4s;
}

.avatar-4 {
  animation-delay: 0.6s;
}

.avatar-5 {
  animation-delay: 0.8s;
}

.avatar-6 {
  animation-delay: 1s;
}
.video-container {
  display: flex;
  align-items: center;
 
  /* background-color: lightgray; */
  margin: 0 auto; /* Center the video */
  overflow: hidden; /* Prevent video from exceeding its container */
}

